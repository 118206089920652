(function() {
  'use strict';

  var requestResetPasswordURL = 'accounts/pw_reset_request';

  var states = {
    active: {
      id: 'active',
      name: 'Active',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    disabled: {
      id: 'disabled',
      name: 'Disabled',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    waiting_for_approval: {
      id: 'waiting_for_approval',
      name: 'Pending',
      labelStyle: 'warning',
      borderStyle: 'pending'
    }
  };

  const sources = [
    { id: 'booking', title: 'risr/apply' },
    { id: 'kaizen', title: 'risr/advance' },
    { id: 'self_register', title: 'Self register' },
    { id: 'auto_register', title: 'SSO auto register' },
    { id: 'import', title: 'Bulk import' },
    { id: 'api', title: 'API' },
    { id: 'quickfill', title: 'Quick fill registration' },
    { id: 'not_classified', title: 'Not classified' }
  ];

  angular.module('component.users')
      .constant('REQUEST_RESET_PASSWORD_URL', requestResetPasswordURL)
      .constant('USER_STATES', states)
      .constant('USER_SOURCES', sources);
})();
