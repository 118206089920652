(function() {
  'use strict';

  function UsersController(
    $state,
    $rootScope,
    Profile,
    Users,
    User,
    UserSearch,
    Auth,
    APIList,
    Organisations,
    Security,
    Utils
  ) {
    var ctrl = this;

    var listName = 'users';

    Utils.setPageTitle('Users');

    ctrl.searchModel = Profile.getListPreferences(listName).current;
    if (ctrl.searchModel.filteredBy === undefined) {
      ctrl.searchModel.filteredBy = {};
    }

    if (ctrl.searchModel.filteredBy.state === undefined) {
      ctrl.searchModel.filteredBy.state = 'active';
    }

    var makeDocs = function(item) {
      return new User(item);
    };

    function setupList(search) {
      ctrl.list = new APIList(Users, {
        search: search,
        model: ctrl.searchModel,
        idField: 'doc._id',
        makeDocs: makeDocs
      });

      ctrl.list.doSearch();

      ctrl.options = {
        trackBy: 'doc._id',
        loadList: ctrl.list.doSearch.bind(ctrl.list)
      };
    }

    UserSearch.search()
      .then(function(search) {
        return setupList(search);
      })
      .then(function() {
        ctrl.loaded = true;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
      });

    ctrl.actions = [
      {
        label: 'New user',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.users.new'); })(),
        showCondition: function() {
          return Security.hasPermission('users.create')
            .then(function(has) {
              if (!has) {
                return false;
              }

              var orgId = Auth.currentOrganisation();
              return Organisations.find(orgId)
                .then(function(org) {
                  return !_.isEmpty(org.allowedAccountTypes);
                });
            });
        },
        klass: 'btn-success'
      }
    ];
  }

  UsersController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'UsersService',
    'UserFactory',
    'UserSearch',
    'AuthService',
    'APIListFactory',
    'OrganisationsService',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.users')
    .controller('UsersController', UsersController);
})();
